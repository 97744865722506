import React, { useState, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { tilloOfferDetails } from "./../graphql/queries/benefitQueries";
import { updateTilloOfferMutation } from "./../graphql/mutations/benefitMutations";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useDataProvider from "./../hooks/dataProvider";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useNotification from "./../hooks/notifications";
import { useOfferFieldSetters } from "./../hooks/vabooHooks";
import useModal from "./../hooks/modal";
import { TilloBrandInfo } from "./TilloBrandListApprovalPage";
import {
  OFFER_MODEL_NAME,
  OFFER_STATUSES,
  REGULAR_TAG_TYPE_ID,
  EXCLUSION_TAG_TYPE_ID,
} from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
  AddButton,
  DeleteButton,
} from "./../mood-ui/Controls";
import {
  ErrorNotification,
  SuccessNotification,
} from "./../mood-ui/Notifications";
import { Heading } from "./../mood-ui/Typography";
import {
  DateField,
  SelectField,
  TextField,
  NumberField,
  ToggleCheckboxField,
  ReadOnlyField,
} from "./../mood-ui/Fields";
import {
  TableContainer,
  TableView,
  THeading,
  TContent,
  THCol,
  TRow,
  TCol,
} from "./../mood-ui/DataTable";
import RichTextEditor from "./../mood-ui/RichTextEditor";
import ImageGalleryModal from "./../mood-ui/ImageGalleryModal";
import { LabelContext } from "../contexts/LabelContext";

const OFFER_DETAILS = gql(tilloOfferDetails);
const UPDATE_OFFER = gql(updateTilloOfferMutation);

const TilloOffer = () => {
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Query.TilloOffer);

  const [tilloOffer, setTilloOffer] = useState(false);

  const [saving, setSaving] = useState(0);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const labelContext = useContext(LabelContext);

  const [merchants] = useDataProvider("merchants");

  const [tags] = useDataProvider("tags", {
    filters: { typeId: [REGULAR_TAG_TYPE_ID] },
  });

  const sortedTags = [...tags].sort((a, b) =>
    a.slug > b.slug ? 1 : a.slug < b.slug ? -1 : 0
  );

  const [exclusionTags] = useDataProvider("tags", {
    filters: { typeId: [EXCLUSION_TAG_TYPE_ID] },
  });

  const sortedExclusionTags = [...exclusionTags].sort((a, b) =>
    a.slug > b.slug ? 1 : a.slug < b.slug ? -1 : 0
  );

  const { goBack } = useBaseNavigation(OFFER_MODEL_NAME);

  const { id } = useParams();

  const { modalState, showModal, closeModal } = useModal();

  const {
    // notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    // notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    merchant_id: 0,
    code: "",
    title: "",
    description: "",
    background: "",
    image: "",
    method: "",
    methodIcon: "",
    cta: "",
    verb: "",
    saving_diff: 0,
    face_value_limit_lower: 0,
    face_value_limit_upper: 0,
    face_value_minor_unit: 0,
    max_cards_per_online: 0,
    max_cards_per_instore: 0,
    terms: "",
    redeem: "",
    redeem_url: "",
    related_offers: "",
    search_terms: "",
    redeem_external_url: false,
    premium_gifted: false,
    premium_acquired: false,
    basic: false,
    public: false,
    start_date: "",
    end_date: "",
    status: "Inactive",
    admin_title: "",
    tags: [],
    exclusionTags: [],
    steps: [],
    labels: [],
    reveal_cta: "",
    card_logo: "",
    card_background: "",
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const {
    handleTagCheck,
    handleExclusionTagCheck,
    handleLabelCheck,
    handleAddOfferStep,
    handleUpdateOfferStep,
    handleDeleteOfferStep,
  } = useOfferFieldSetters(formData, stateMethods);

  const {
    // data,
    loading,
    error,
  } = useQuery(OFFER_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.tilloOffer) return false;

      const tagIds = result.tilloOffer.tags.map((tag) => tag.id);

      const exclusionTagIds = result.tilloOffer.exclusionTags.map(
        (tag) => tag.id
      );

      const steps = result.tilloOffer.steps.map((step) => {
        return {
          title: step.title,
          text: step.text,
        };
      });

      const labels = result.tilloOffer.labels.map((l) => l.id);

      setFormData({
        ...result.tilloOffer,
        merchant_id: result.tilloOffer.merchant.id,
        tags: tagIds,
        exclusionTags: exclusionTagIds,
        steps,
        labels,
      });

      setSaving(parseFloat(result.tilloOffer.saving));

      const tilloObj = { ...result.tilloOffer.tillo_offer };

      const newTilloOffer = {
        slug: tilloObj.slug,
        name: tilloObj.name,
        status: {
          code: tilloObj.status_code,
        },
        currency: tilloObj.currency,
        discount: tilloObj.discount,
        last_updated: tilloObj.last_updated,
        async_only: tilloObj.async_only,
        gc_pool: tilloObj.gc_pool,
        vat: {
          exempt: tilloObj.vat_exempt,
          type: tilloObj.vat_type,
          rate: tilloObj.vat_rate,
        },
        digital_face_value_limits: {
          lower: tilloObj.digital_face_value_limits_lower,
          upper: tilloObj.digital_face_value_limits_upper,
          minor_unit: tilloObj.digital_face_value_limits_minor_unit,
        },
        categories: tilloObj.categories.map((v) => v.name),
        transaction_types: tilloObj.transaction_types.map((v) => v.name),
        delivery_methods: tilloObj.delivery_methods.map((v) => v.name),
        countries_served: tilloObj.countries_served.map((v) => v.name),
        denominations: tilloObj.denominations.map((v) => v.name),
        digital_denominations: tilloObj.digital_denominations.map(
          (v) => v.name
        ),
        detail: {
          assets: {
            logo_url: tilloObj.assets_logo_url,
            gift_card_url: tilloObj.assets_gift_card_url,
          },
          balance_enquiry_url: tilloObj.balance_enquiry_url,
          barcode: tilloObj.barcode,
          description: tilloObj.description,
          expiry: tilloObj.expiry,
          redemption_methods: tilloObj.redemption_methods.map((v) => v.name),
          terms_and_conditions_url: tilloObj.terms_and_conditions_url,
          terms_and_conditions_copy: tilloObj.terms_and_conditions_copy,
          website_url: tilloObj.website_url,
          redemption_instructions_url: tilloObj.redemption_instructions_url,
          online_order_restrictions: tilloObj.online_order_restrictions,
          instore_order_restrictions: tilloObj.instore_order_restrictions,
          digital_general_restrictions: tilloObj.digital_general_restrictions,
        },
      };
      newTilloOffer.serialized_data = JSON.stringify({ ...newTilloOffer });
      setTilloOffer(newTilloOffer);
    },
  });

  const [updateOffer, { loading: isUpdating }] = useMutation(UPDATE_OFFER, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleImageSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "image", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackgroundSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        handleFieldOnChange({
          target: { name: "background", value: file.public_url },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const computeNewSaving = (e) => {
    const diffValue = e.target.value;
    const newSaving = parseFloat(tilloOffer.discount) + parseFloat(diffValue);
    if (isNaN(newSaving)) {
      setSaving(tilloOffer.discount);
    } else {
      setSaving(newSaving);
      // if (newSaving < -2 || newSaving > 2) {
      //   alert(
      //     "ERROR. To big saving diff value. Cannot be applied. Revering to the original discount value."
      //   );
      //   setSaving(tilloOffer.discount);
      // } else {
      //   setSaving(newSaving);
      // }
    }
    // handleFieldOnChange({ target: { name: "saving_diff", value: diffValue } });
    handleFieldOnChange(e);
  };

  const handleSave = () => {
    const data = {
      ...formData,
      steps: JSON.stringify(formData.steps),
    };
    updateOffer({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  if (
    !formData ||
    !tilloOffer ||
    !tags ||
    !exclusionTags ||
    !formData.exclusionTags ||
    !formData.tags ||
    loading ||
    !sortedExclusionTags ||
    !sortedTags
  )
    return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update reward card"
              description="Every card must belong to a merchant. A merchant can 
                          container many cards or offers. If you are updating a card before 
                          the merchant is added, you can temporarily assign it to 
                          'Vaboo Generic Merchant'."
            />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Admin settings"
                      description="Internal settings used for managing and monitoring offers."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.merchant_id}
                      values={merchants}
                      label="Merchant"
                      name="merchant_id"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="id"
                      labelKey="title"
                      isRequired={true}
                      error={fieldError("merchant")}
                      description="A merchant can own many offers"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="admin_title"
                      label="Admin title"
                      value={formData.admin_title}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("admin_title")}
                      isRequired={true}
                      maxlength={72}
                      description="For internal use only"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="code"
                      label="URL slug"
                      value={formData.code}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("code")}
                      isRequired={true}
                      maxlength={64}
                      description="Unique path for the URL"
                      pattern="^[a-z0-9]+(?:-[a-z0-9]+)*"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.status}
                      values={OFFER_STATUSES}
                      label="Status"
                      name="status"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                      error={fieldError("status")}
                      description="Show or hide this card"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateField
                      name="start_date"
                      label="Start date"
                      value={formData.start_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("start_date")}
                      isRequired={true}
                      description="Hidden until this date"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateField
                      name="end_date"
                      label="End date"
                      value={formData.end_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("end_date")}
                      isRequired={true}
                      description="Hidden after this date"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Artwork"
                      description="With the exception of logos, images should not contain text, should not
                                  include promotions, and should not be seasonal. Use descriptive file names 
                                  so images can be found via search in the future. 
                                  E.g. tesco-logo.png, hotels-com-background.jpeg."
                    />
                    <p className="mt-3 mb-2 text-sm text-gray-500 select-none">
                      Tillo logo and card image
                    </p>
                    <img
                      className={`h-[60px] inline border border-[#dfe2e8] mr-2 select-none`}
                      src={formData.card_logo}
                      alt="Logo"
                      crossOrigin="anonymous"
                    />
                    <img
                      className={`h-[60px] inline border border-[#dfe2e8] select-none`}
                      src={formData.card_background}
                      alt="Logo"
                      crossOrigin="anonymous"
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col
                width={8}
                extraStyles="flex items-around align-center flex-wrap select-none relastive"
              >
                <div
                  className={`flex items-censter justify-center relative w-full aspect-[16/7] cursor-pointer relative `}
                >
                  <div
                    className={`h-[55%] aspect-[1/1] border-2 z-40 border-white cursor-pointer absolute top-7 left-7`}
                    onClick={handleImageSelectModal}
                  >
                    <img
                      src={
                        formData.image && formData.image.length > 0
                          ? formData.image
                          : "https://content.vaboo.co.uk/images/20230119_005519_logo-image.png"
                      }
                      alt="Logo"
                      className={`object-fill w-full hover:outline-none hover:shadow-outline peer`}
                      crossOrigin="anonymous"
                      onError={(e) => {
                        e.target.className += " opacity-0";
                      }}
                      onLoad={(e) => {
                        e.target.parentNode.className +=
                          " bg-red-600 outline outline-white outline-1 outline-offset-[-3px]";
                        e.target.className = e.target.className.replace(
                          /opacity-0/g,
                          ""
                        );
                      }}
                    />
                    <span className="absolute text-xs leading-2 top-1/2 -translate-y-1/2 left-0 right-0 p-3 text-center text-red-300 -z-1 invisible peer-[.opacity-0]:visible">
                      There was an error. Please try another image.
                    </span>
                  </div>
                  <div
                    className={`aspect-[16/7] cursor-pointer hover:outline-none hover:shadow-outline `}
                    onClick={handleBackgroundSelectModal}
                  >
                    <img
                      src={
                        formData.background && formData.background.length > 0
                          ? formData.background
                          : "https://content.vaboo.co.uk/images/20230119_005706_background-image.png"
                      }
                      alt="Background"
                      className={`object-contain max-h-full w-full peer`}
                      crossOrigin="anonymous"
                      onError={(e) => {
                        e.target.className += " opacity-0";
                      }}
                      onLoad={(e) => {
                        e.target.parentNode.className += " bg-red-600";
                        e.target.className = e.target.className.replace(
                          /opacity-0/g,
                          ""
                        );
                      }}
                    />
                    <span className="absolute top-8 right-8 text-red-300 -z-1 invisible peer-[.opacity-0]:visible">
                      There was an error. Please try another image.
                    </span>
                  </div>
                  {(!formData.background || !formData.background.length) && (
                    <div
                      className={`bg-black opacity-5 h-full w-full absolute top-0 left-0 pointer-events-none `}
                    ></div>
                  )}
                </div>
                <p className="mt-1.5 text-xs text-gray-400 pl-0.5 select-none">
                  A red border will appear around an image with sizing issues.
                </p>
              </Col>
            </Row>
            {/* <TextField
              name="image"v
              label="Image"
              value={formData.image}
              onChange={handleFieldOnChange}
              error={fieldError("image")}
              isRequired={true}
            />
            <TextField
              name="background"
              label="Background"
              value={formData.background}
              onChange={handleFieldOnChange}
              error={fieldError("background")}
              isRequired={true}
            /> */}

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Primary details"
                      description="This essential text content should include enough information
                                  to be used on its own. These are the only elements of an offer that are scanned 
                                  by the search function in the platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles="">
                    <TextField
                      name="title"
                      label="Headline"
                      value={formData.title}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("title")}
                      isRequired={true}
                      maxlength={96}
                      description="Always shown with offer"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <TextField
                      name="search_terms"
                      label="Extra search terms"
                      value={formData.search_terms}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("search_terms")}
                      isRequired={false}
                      maxlength={96}
                      description="Associated words, mispellings, categories etc."
                    />
                  </Col>
                  <Col width={12} extraStyles="">
                    <RichTextEditor
                      cols={5}
                      rows={5}
                      name="description"
                      value={formData.description}
                      label="Description"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("description")}
                      isRequired={true}
                      description="Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Discount"
                      description="We cap the order value between £5 and £500 regardless of the Tillo limit."
                    />
                    <p className="w-full text-sm text-gray-500 mt-2 select-none">
                      Tillo saving: {tilloOffer.discount}%
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={3}>
                    {hasAccess(
                      !Permissions.Mutation.UpdateTilloOfferDiscountAdjustment
                    ) && (
                      <ReadOnlyField
                        label="% adjustment"
                        value={formData.saving_diff}
                        description="Adjust saving by X%"
                      />
                    )}
                    {hasAccess(
                      Permissions.Mutation.UpdateTilloOfferDiscountAdjustment
                    ) && (
                      <NumberField
                        name="saving_diff"
                        label="% adjustment"
                        value={formData.saving_diff}
                        onChange={(e) => computeNewSaving(e)}
                        error={fieldError("saving_diff")}
                        isRequired={true}
                        min={-1 * parseInt(tilloOffer.discount)}
                        max={100 - parseInt(tilloOffer.discount)}
                        description="Adjust saving by X%"
                        step={0.1}
                      />
                    )}
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="face_value_limit_lower"
                      label="Min order value"
                      value={formData.face_value_limit_lower}
                      description={`Tillo Value: £${formData.tillo_offer.digital_face_value_limits_lower}`}
                      onChange={handleFieldOnChange}
                      error={fieldError("face_value_limit_lower")}
                      isRequired={true}
                      min={
                        parseInt(
                          formData.tillo_offer.digital_face_value_limits_lower
                        ) < 5
                          ? 5
                          : parseInt(
                              formData.tillo_offer
                                .digital_face_value_limits_lower
                            )
                      }
                      max={parseInt(formData.face_value_limit_upper) - 1}
                      gbp
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="face_value_limit_upper"
                      label="Max order value"
                      value={formData.face_value_limit_upper}
                      description={`Tillo Value: £${formData.tillo_offer.digital_face_value_limits_upper}`}
                      onChange={handleFieldOnChange}
                      error={fieldError("face_value_limit_upper")}
                      isRequired={true}
                      min={parseInt(formData.face_value_limit_lower) + 1}
                      max={
                        parseInt(
                          formData.tillo_offer.digital_face_value_limits_upper
                        ) > 500
                          ? 500
                          : parseInt(
                              formData.tillo_offer
                                .digital_face_value_limits_upper
                            )
                      }
                      gbp
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="face_value_minor_unit"
                      label="Order increments"
                      value={formData.face_value_minor_unit}
                      description={`Tillo Value: £${formData.tillo_offer.digital_face_value_limits_minor_unit}`}
                      onChange={handleFieldOnChange}
                      error={fieldError("face_value_minor_unit")}
                      isRequired={true}
                      gbp
                      step={0.01}
                      min={
                        parseInt(
                          formData.tillo_offer
                            .digital_face_value_limits_minor_unit
                        ) < 0.01
                          ? 0.01
                          : parseInt(
                              formData.tillo_offer
                                .digital_face_value_limits_minor_unit
                            )
                      }
                      max={parseInt(
                        formData.tillo_offer.digital_face_value_limits_upper
                      )}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="max_cards_per_online"
                      label="Max cards online"
                      description={`Tillo Value: ${formData.tillo_offer.online_order_restrictions}`}
                      value={formData.max_cards_per_online}
                      onChange={handleFieldOnChange}
                      error={fieldError("max_cards_per_online")}
                      isRequired={true}
                      step={1}
                      min={0}
                    />
                  </Col>
                  <Col width={3}>
                    <NumberField
                      name="max_cards_per_instore"
                      label="Max cards in-store"
                      description={`Tillo Value: ${formData.tillo_offer.instore_order_restrictions}`}
                      value={formData.max_cards_per_instore}
                      onChange={handleFieldOnChange}
                      error={fieldError("max_cards_per_instore")}
                      isRequired={true}
                      step={1}
                      min={0}
                    />
                  </Col>
                  <Col width={6}>
                    <p className="text-gray-400 text-xs select-none">
                      Max/min cards online/in-store show how many cards can be
                      used per order. Leave this alone unless there is an error
                      with the Tillo values. Use '0' for unlimited. If a card
                      cannot be used online or in-store, the system overrides
                      these values.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Supporting content"
                      description="Control the elements that belong to this offer. Character
                                  limits protect an offer's layout. Always preview 
                                  new offers by logging into the live platform."
                    />
                    <p className="text-xs text-[#592373] opacity-60 underline underline-offset-4 mt-4 mb-7 select-none">
                      <a
                        href={`https://pictogrammers.com/library/mdi/`}
                        target="_BLANK"
                        rel="noreferrer"
                      >
                        Browse icon list
                      </a>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="cta"
                      label="CTA 1"
                      value={formData.cta}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("cta")}
                      isRequired={true}
                      maxlength={96}
                      description="Pre-redemption button text"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="verb"
                      label="Context"
                      value={formData.verb}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("verb")}
                      isRequired={true}
                      maxlength={14}
                      description="E.g 'Save'"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ReadOnlyField
                      name="saving"
                      label="Saving %"
                      value={saving}
                      description="Calculated from above values"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="related_offers"
                      label="Related offers"
                      value={formData.related_offers}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                      error={fieldError("related_offers")}
                      maxlength={128}
                      description="Comma-separated list of IDs"
                      tweaks="pr-8"
                      pattern="^([0-9]+)(,\s?[0-9]+)*\s?$"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <TextField
                      name="method"
                      label="Method"
                      value={formData.method}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("method")}
                      isRequired={true}
                      maxlength={12}
                      description="E.g. 'Reward Card' "
                    />
                  </Col>
                  <Col width={4} extraStyles="relative ">
                    <TextField
                      name="methodIcon"
                      label="Method icon"
                      value={formData.methodIcon}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                      error={fieldError("methodIcon")}
                      isRequired={true}
                      maxlength={36}
                      description="Use 'mdi-' + 'icon_name'"
                      tweaks="pr-8"
                      pattern="[a-z0-9]+(?:-[a-z0-9]+)*"
                    />
                    <span
                      className={`absolute top-7 pt-[3px] right-3 text-[#b07fc7] -mr-0.5 text-[17px] mdi ${formData.methodIcon}`}
                    ></span>
                  </Col>
                  <Col width={12}>
                    <p className="text-sm mb-0.5 pb-[2px] leading-5 text-gray-500 pl-1 select-none">
                      Steps
                    </p>
                    {formData &&
                      formData.steps &&
                      formData.steps.length === 0 && (
                        <div className="p-3 border border-[#dfe2e8] bg-white flex">
                          <AddButton
                            label="Add step"
                            onClick={handleAddOfferStep}
                            icon
                          />
                          <div className="ml-2 mt-0.5 pt-px mr-auto w-[75%] text-sm text-gray-500">
                            Add steps to explain how this offer works
                          </div>
                        </div>
                      )}
                    {formData &&
                      formData.steps &&
                      formData.steps.length > 0 && (
                        <TableContainer tweaks="relative">
                          <TableView>
                            <THeading>
                              <TRow>
                                <THCol tweaks="w-4" name="title" value="#" />
                                <THCol
                                  tweaks="text-left"
                                  name="title"
                                  value="Title"
                                />
                                <THCol
                                  tweaks="text-left"
                                  name="text"
                                  value="Text"
                                />
                                <THCol
                                  width="w-[38.5px]"
                                  tweaks="px-0.5 py-0.5"
                                >
                                  <AddButton
                                    label="Add step"
                                    onClick={handleAddOfferStep}
                                    icon
                                  />
                                </THCol>
                              </TRow>
                            </THeading>
                            <TContent>
                              {formData.steps.map((step, index) => (
                                <TRow key={index}>
                                  <TCol tweaks="px-3 py-0.5 text-center">
                                    {index + 1}
                                  </TCol>
                                  <TCol tweaks="px-0.5 py-0.5 w-[25%]">
                                    <TextField
                                      name={`title_${index}`}
                                      value={step.title}
                                      flat
                                      isRequired={true}
                                      onChange={handleUpdateOfferStep}
                                      maxlength={36}
                                    />
                                  </TCol>
                                  <TCol tweaks="px-0.5 py-0.5">
                                    <TextField
                                      name={`text_${index}`}
                                      value={step.text}
                                      flat
                                      isRequired={true}
                                      onChange={handleUpdateOfferStep}
                                      maxlength={196}
                                    />
                                  </TCol>
                                  <TCol
                                    width="w-[38.5px]"
                                    tweaks="px-0.5 py-0.5"
                                  >
                                    {formData.steps.length > 2 && (
                                      <DeleteButton
                                        icon
                                        onClick={() => {
                                          handleDeleteOfferStep(index);
                                          setDraft(true);
                                        }}
                                      />
                                    )}
                                  </TCol>
                                </TRow>
                              ))}
                            </TContent>
                          </TableView>
                          <p className="mt-1.5 text-xs text-gray-400 pl-0.5 select-none">
                            Use at least 2 steps to explain how to redeem this
                            offer
                          </p>
                        </TableContainer>
                      )}
                  </Col>
                  <Col width={12}>
                    <RichTextEditor
                      cols={5}
                      rows={5}
                      name="terms"
                      value={formData.terms}
                      label="Additional terms"
                      onChange={handleFieldOnChange}
                      error={fieldError("terms")}
                      isRequired={true}
                      description="Added before Tillo terms. Use (ctrl/cmd) + shift + V to paste text without breaking the formatting"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Privileges"
                      description="Allow the use of this offer in marketing and control visibility across membership tiers."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-0 py-1 px-px">
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="public"
                      name="public"
                      label="Public"
                      isChecked={formData.public}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      flush
                      description="Show in marketing"
                    />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="basic"
                      name="basic"
                      label="Basic"
                      isChecked={formData.basic}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      flush
                      description="Is not premium"
                    />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="premium_gifted"
                      name="premium_gifted"
                      label="Gifted"
                      isChecked={formData.premium_gifted}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      flush
                      description="Was given premium"
                    />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      id="premium_acquired"
                      name="premium_acquired"
                      label="Upgraded"
                      isChecked={formData.premium_acquired}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      flush
                      description="Pays for premium access"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="User types"
                      description="Control which users can access this offer based on their label type."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-0 sm:grid-cols-5 py-1 px-px">
                  {labelContext.labels.map((label) => (
                    <Col key={label.id} extraStyles="capitalize" width={1}>
                      <ToggleCheckboxField
                        id={label.id}
                        name="labels"
                        label={label.name}
                        isChecked={
                          formData.labels.indexOf(label.id) > -1 ? true : false
                        }
                        onChange={(e) => {
                          setDraft(true);
                          handleLabelCheck(e);
                        }}
                        flush
                        description="Enable for type"
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            {sortedExclusionTags && sortedExclusionTags.length && (
              <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
                <Col width={12}>
                  <Heading
                    size="md"
                    text="Exclusion tags"
                    description="Used to group offers types that must be excluded from
                                certain platforms. Internal use only."
                  />
                </Col>
                <Col width={12}>
                  <Row tweaks="gap-0 sm:grid-cols-5s py-1 px-px">
                    {sortedExclusionTags.map((tag) => (
                      <Col
                        width={2}
                        key={tag.id}
                        extraStyles="capitalizes sm:col-span-1s"
                      >
                        <ToggleCheckboxField
                          id={tag.id}
                          name="exclutionTags"
                          tag={tag.name}
                          isChecked={
                            formData.exclusionTags.indexOf(tag.id) > -1
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setDraft(true);
                            handleExclusionTagCheck(e);
                          }}
                          flush
                          label={tag.title}
                          description={tag.slug}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}

            {sortedTags && sortedTags.length && (
              <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
                <Col width={12}>
                  <Heading
                    size="md"
                    text="Category tags"
                    description="An offer must have at least one tag to be organically
                                discoverable in the platform. Tags define the
                                structure of categories for navigation."
                  />
                </Col>
                <Col width={12}>
                  <Row tweaks="gap-0 sm:grid-cols-5s pb-1 px-px">
                    {sortedTags.map((tag) => (
                      <Col width={2} key={tag.id} extraStyles="">
                        <ToggleCheckboxField
                          id={tag.id}
                          name="tags"
                          tag={tag.name}
                          isChecked={
                            formData.tags.indexOf(tag.id) > -1 ? true : false
                          }
                          onChange={(e) => {
                            setDraft(true);
                            handleTagCheck(e);
                          }}
                          flush
                          label={tag.title}
                          description={tag.slug}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}

            <Row tweaks="border-[#dfe2e8] py-7 mx-5 gap-y-0">
              <Col width={12}>
                <Heading
                  size="md"
                  text="Tillo data"
                  description="This is all the information provided by Tillo."
                />
              </Col>
              <Col width={12}>
                <TilloBrandInfo brand={tilloOffer} />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            {hasAccess(Permissions.Mutation.UpdateTilloOffer) && (
              // <SaveButton onClick={handleSave} />
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={!isDraft || formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <ImageGalleryModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default TilloOffer;
